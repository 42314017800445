
import { Mutations, ProgressPanelState } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
	name: "ProgressPanel",
	data() {
		return {
			snackbar: false,
			timeout: 5000,
		};
	},
	computed: {
		progressPanel: function (): any {
			return this.$store.state.progressPanel;
		},
	},
	methods: {},
	watch: {
		progressPanel: {
			deep: true,
			handler() {
				if (this.progressPanel.state === ProgressPanelState.InProgress) {
					this.snackbar = true
				} 
				else if (this.progressPanel.state === ProgressPanelState.Completed ||
					this.progressPanel.state === ProgressPanelState.Failed) {
					setTimeout(() => {
						this.snackbar = false;
						this.$store.commit(Mutations.SetProgressPanel, {
							text: '',
							state: ProgressPanelState.Dismissed
						})
					}, this.timeout);
				}

			},
		},
	},
});
