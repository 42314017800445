import { IAssociate, ICompany, ICompanyAccount, IGroup, IProject } from "@/api";

// session storage
export enum SessionKeys {
  AuthToken = 'authtoken'
}

// root store
export interface IState {
  terms: string,
  agreement: string,
  associate: IAssociate
  company: ICompany & {
    account: ICompanyAccount
    projects: IProject[]
    groups: IGroup[]
    associates: IAssociate[]
  }
  error: {
    display?: boolean
    message?: string
  }
  toast: {
    icon: {
      name: string,
      color: string
    },
    text: string,
    isBottom: boolean
  },
  progressPanel: {
    text: string
    state: ProgressPanelState
  },
  isLoading: boolean
  forceLoading: boolean
  supportDialog: boolean
  hideNavDrawer: boolean
}
export enum ProgressPanelState {
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed",
  Dismissed = "Dismissed" //initiall and final state
}
export enum Actions {
  GetTermsAndAgreement = 'getTermsAndAgreement',
  InitPublic = 'initPublic',
  Init = 'init',
  InitUser = "initUser",
  GetUserInfo = 'getUserInfo',
  GetCompany = 'getCompany',
  GetCompanyAccount = 'getCompanyAccount',
  GetCompanyProjects = 'getCompanyProjects',
  GetCompanyAssociates = "getCompanyAssociates",
  GetCompanyGroups = "getCompanyGroups",
  Logout = 'logout',
  ClearSession = 'clearSession',
  DisplayError = 'displayError',
}
export enum Mutations {
  ResetState = 'resetState',
  SetAssociate = 'setAssociate',
  SetCompany = 'setCompany',
  SetError = 'setError',
  ClearError = 'clearError',
  SetToast = 'setToast',
  SetProgressPanel = 'setProgressPanel',
  SetIsLoading = 'setIsLoading',
  SetForceLoading = 'setForceLoading',
  SetSupportDialog = 'setSupportDialog',
  SetHideNavDrawer = 'setHideNavDrawer',
  SetTermsAndAgreement = 'setTermsAndAgreement'
}

// scans module 
export enum ScansActions {
  Download = 'scans/download',
  Delete = 'scans/delete',
  DownloadFiles = 'scans/downloadFiles',
  DeleteFile = 'scans/deleteFile'
}