import axios from "axios";
import { baseUrl, IBaseResponse, transformResponse } from "..";
import { AttachmentsEntities, AttachmentsProjectFileTypes, AttachmentsScanFileTypes, IAttachmentsGetAllResponse } from "./models";

const attachmentsUrl = () => baseUrl + '/attachments'
const update = async (id: number, request: any): Promise<IBaseResponse> => {
  const formData = new FormData();
  for (const [key, val] of Object.entries(request) as any) {
    if (val !== undefined)
      formData.append(key, val)
  }

  return transformResponse(await axios.put(
    attachmentsUrl() + "/" + id,
    formData
  ))
}
const deleteA = async (id: number): Promise<IBaseResponse> => {
  return transformResponse(await axios.delete(
    attachmentsUrl() + '/' + id,
  ))
}
const download = async (id: number) => {
  const response: any = await axios.get(
    attachmentsUrl() + '/' + id + "/download",
    {
      responseType: 'arraybuffer',
    }
  )
  const disposition = response.headers['content-disposition']
  const fileName = disposition?.split('filename=')?.[1] || 'file'
  const type = response.headers['content-type']
  const blob = await new Blob([response.data], { type });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const getImage = async (id: number): Promise<any> => {
  return axios.get(
    attachmentsUrl() + '/' + id + "/download",
    {
      responseType: 'arraybuffer',
    }
  )
}

const reorder = async (pid: number, order: number[]): Promise<IBaseResponse> => {
  // const formData = new FormData();
  // formData.append('entity', 'projects')
  // formData.append('entity_id', pid.toString())
  // formData.append('ids', order as any)

  return transformResponse(await axios.put(
    baseUrl + '/reorder/attachments', {
    'entity': 'projects',
    'entity_id': pid,
    'ids': order
  }
  ))
}

const projects = {
  getAll: async (projectId: number, projects_areas_of_interest_id?: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        get_total_count: true,
        search_fields: {
          entity: AttachmentsEntities.Projects,
          entity_id: projectId,
          projects_areas_of_interest_id
        },
        "sort_field": "order_id",
      }
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: {
    data: File,
    projects_areas_of_interest_name: string,
    title?: string,
    description?: string
    file_type?: AttachmentsProjectFileTypes,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Projects)
    formData.append('entity_id', projectId.toString())
    formData.append('file_type', request.file_type || AttachmentsProjectFileTypes.SitePhotos)

    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }

    return transformResponse(await axios.post(
      attachmentsUrl(),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
  uploadScan: async (projectId: number, request: {
    data: File,
    projects_areas_of_interest_name: string,
    title?: string,
    description?: string
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Projects)
    formData.append('entity_id', projectId.toString())
    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }
    return transformResponse(await axios.post(
      baseUrl + '/projects/upload_attachments',
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
  uploadFSNZip: async ( request: {
    data: File,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('zip-file', request.data);
    return transformResponse(await axios.post(
      baseUrl + '/projects/upload_flex',
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  }
}

const scans = {
  getAll: async (scanId: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        get_total_count: true,
        search_fields: {
          entity: AttachmentsEntities.Scans,
          entity_id: scanId
        }
      }
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (scanId: number, file: File, fileType: AttachmentsScanFileTypes): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Scans)
    formData.append('entity_id', scanId.toString())
    formData.append('data', file);
    formData.append('file_type', fileType)
    return transformResponse(await axios.post(
      attachmentsUrl(),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
}

const reports = {
  getAll: async (projectId: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        get_total_count: true,
        search_fields: {
          entity: AttachmentsEntities.ProjectReports,
          entity_id: projectId
        }
      },
      // row_count: 100
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: {
    data: File,
    name?: string,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.ProjectReports)
    formData.append('entity_id', projectId.toString())

    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }

    return transformResponse(await axios.post(
      attachmentsUrl(),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },
}

const documents = {
  getAll: async (projectId: number): Promise<IAttachmentsGetAllResponse> => {
    const params = {
      list_info: {
        search_fields: {
          entity: AttachmentsEntities.Projects,
          entity_id: projectId,
          "projects_areas_of_interest_id": null
        }
      }
    };
    return transformResponse(await axios.get(
      attachmentsUrl(),
      {
        params
      }
    ))
  },
  add: async (projectId: number, request: {
    data: File,
    title?: string,
    description?: string
    file_type?: AttachmentsProjectFileTypes,
  }): Promise<IBaseResponse> => {
    const formData = new FormData();
    formData.append('entity', AttachmentsEntities.Projects)
    formData.append('entity_id', projectId.toString())
    formData.append('file_type', AttachmentsProjectFileTypes.ProjectDocuments)

    for (const [key, val] of Object.entries(request)) {
      if (val)
        formData.append(key, val)
    }

    return transformResponse(await axios.post(
      attachmentsUrl(),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    ))
  },

}
export const attachments = {
  update,
  delete: deleteA,
  download,
  getImage,
  reorder,

  projects,
  scans,
  reports,
  documents
}
export * from "./models"